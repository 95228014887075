@import './reset.scss';
@import './variables.scss';

/** Some useful general classes that comes handy in all app ***********************************************************/

.ra-padding-reset {
  // By default react admin adds 24px padding to containers, so we kill it with a margin when we need it.
  // (e.g. in pages that have tabs and we wrap react admin content in a div)
  margin: -24px !important;
}

.ra-margin-top-reset {
  margin-top: -24px !important;
}

.ra-padding-reset {
  // By default react admin adds 24px padding to containers, so we kill it with a margin when we need it.
  // (e.g. in pages that have tabs and we wrap react admin content in a div)
  margin: -24px !important;
}

.ra-margin-top-reset {
  margin-top: -24px !important;
}

.ra-margin-top {
  margin-top: 24px !important;
}

.highlight {
  padding: 3px;
  border-radius: 2px;
  background-color: transparentize($highlight, 0.6);
}

.highlight-warn {
  @extend .highlight;
  background-color: transparentize($danger, 0.8);
}

.highlight-good {
  @extend .highlight;
  background-color: transparentize($secondary, 0.8);
}

.highlight-flash {
  transition: box-shadow 0.5s ease-in-out;
  box-shadow: 0 4px 8px 0 rgba(255, 25, 25, 0.2);
}

.datagrid-button {
  padding: 0 8px !important;
}

.datagrid-input {
  font-size: 1em !important;
  height: 28px;
}

.instructions {
  // This is style for every place where we have a text that explains how to do something
  &:before {
    font-weight: bold;
    content: '👉 Instrucciones: ';
  }
  font-size: 0.8em;
  padding: 0.6em;
  background-color: lightgray;
  border-radius: 5px;
}
