@import '../../theming/variables.scss';

#my-layout {
  .logo {
    margin: 8px auto 8px 0;
    height: 50px;
  }

  #my-app-bar {
    .user-companies {
      margin-right: auto;

      .select {
        color: white;
        font-size: 20px;
        height: 30px;
      }
  
      .company-settings {
        color: white;
      }
    }
    
    .bank-button {
      color: white;
      background-color: $secondary;
      margin-right: 30px;
    }
  }
}

@media screen and (max-width: $medium) {
  #my-layout {
    .logo {
      margin: 0 auto;
    }

    .select {
      font-size: 1rem;
      height: 30px ;
    }

    #my-app-bar {
      .user-companies {
        display: none;
      }

      .bank-button {
        display: none;
      }
    }
  }
}
