/** General styles, resetting or conf ************************************************************************************/

@font-face {
  font-family: 'Nexa';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Nexa Regular'), local('Nexa-Regular'),
    url('fonts/NexaRegular.otf') format('opentype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* Chrome, Safari, Edge, Opera hide number input arrows*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox hide number input arrows*/
input[type='number'] {
  -moz-appearance: textfield;
}

/** Some overrides and util workarounds ************************************************************************************/

.MuiButton-contained {
  color: white !important;
}
